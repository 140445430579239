<template>
  <div class="row p-15 skeleton-card-wrap">
  <div v-for="index in computedCount" :key="index" class="skeleton-card p-15">
    <div class="skeleton-thumbnail"></div>
    <div class="skeleton-text-line"></div>
    <div class="skeleton-text-line short"></div>
  </div>
</div>
</template>

<script>
import { isMobile } from "mobile-device-detect";
export default {
  name: 'SkeletonCard',
  props: {
    count:{
      type : Number,
      default: 3,
    }
  },
  computed: {
    computedCount() {
      return isMobile ? 1 : this.count;
    },
  }
};
</script>

<style lang="scss">
.skeleton-card-wrap{
  gap: 30px;
}
.skeleton-card {
  padding: 15px;
  border-radius: 8px;
  background-color: transparent;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 1 auto;
  border: 3px solid $green;
}
.skeleton-card div::before{
  content: "";
    display: block;
    position: absolute;
    top: 0;
    left: -100%;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
    -webkit-animation: shimmer 2s infinite;
    animation: shimmer 2s infinite;
}
.skeleton-card > div{
  position: relative;
  overflow: hidden;
}
.skeleton-thumbnail {
  width: 100%;
  height: 250px;
  background-color: #e0e0e0;
  border-radius: 8px;
  margin-bottom: 12px;
}

.skeleton-text-line {
  width: 100%;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 4px;
  margin-bottom: 8px;
}

.skeleton-text-line.short {
  width: 60%;
}
@keyframes shimmer {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
} 
</style>