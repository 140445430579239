<template>
    <div class="container">
        <div class="gridCategory">
            <div class="category-item" v-for="category, index in categories.slides" :key="index"
                :style="{ backgroundImage: `url(${category.optImage})` }">
                <b-link :to="category.link">{{ category.title }} &rarr;</b-link>
            </div>
        </div>
        <div class="gridCategory_slider ">
            <VueSlickCarousel :dots="true" :arrows="true">
                <div class="category-item" v-for="category, index in categories.slides" :key="index"
                    :style="{ backgroundImage: `url(${category.optImage})` }">
                    <div class="category-overlay">
                    <b-link :to="category.link">{{ category.title }} &rarr;</b-link>
                    </div>
                </div>
            </VueSlickCarousel>
        </div>
    </div>
</template>

<script>
import { isMobile, isTablet, isBrowser } from "mobile-device-detect";
import VueSlickCarousel from 'vue-slick-carousel';

export default {
    computed: {
        categories() {
            let categorySlider = this.$store.getters["sliders/getSliderByIdentifier"]("homepage_categories");

            if (categorySlider.slides != null) {
                categorySlider.slides.forEach((element) => {
                    element.optImage = element.media.url;
                    // if (this.mobileCheck == "browser") {
                    // } else if (this.mobileCheck == "tablet") {
                    //     element.optImage = element.media.larger;
                    // } else {
                    //     element.optImage = element.media.medium;
                    // }
                });
            }
            return categorySlider;
        },
        mobileCheck() {
            if (isBrowser) {
                return "browser";
            }
            if (isMobile && isTablet) {
                return "tablet";
            }
            return "mobile";
        },
    },
    components: { VueSlickCarousel }
}
</script>

<style lang="scss">
.category-item {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    border-radius: 8px;
    width: 525px !important;
    position: relative;
    height: 300px;
    box-shadow: inset 0px -20px 127px 0 #00000082;
}

.mobile,
.tablet {
    .category-item {
        width: 100% !important;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 8px;
        position: relative;
        height: 255.38px !important;
        box-shadow: inset 0px -20px 127px 0 #00000082;

    }
    
}

.category-item .category-overlay {
    background-color: #7db80033;
    width: 100%;
    height: 100%;
    border-radius: 8px;
}

.category-item a {
    color: #fff !important;
    font-size: 35px;
    font-style: italic;
    font-weight: 800;
    line-height: 56px;
    text-align: left;
    top: 66%;
    position: absolute;
    left: 6%;
}
</style>