<template>
    <div class="unsereBestseller">
        <b-container>
            <div>
                <h2 class="heading text-center">UNSERE BESTSELLER</h2>
            </div>
            <clientOnly>
            <SkeletonCard v-if="bestSellerProduct == null" />
            </clientOnly>
            <div class="product_list product-listing" v-if="bestSellerProduct != null">
                <ProductCard v-for="product in bestSellerProduct.slice(0, 3)" :key="product.id" :product="product" />
            </div>
            <div class="product_list_slider mb-20" v-if="bestSellerProduct != null">
                <VueSlickCarousel :dots="true" :arrows="true">
                    <ProductCard v-for="product in bestSellerProduct.slice(0, 3)" :key="product.id"
                        :product="product" />
                </VueSlickCarousel>
            </div>
        </b-container>
    </div>
</template>
<script>

import VueSlickCarousel from 'vue-slick-carousel';
import ProductCard from "@/esf_kerkrade_vitanatura/core/components/ProductCard.vue";
import SkeletonCard from "@/esf_kerkrade_vitanatura/core/components/SkeletonCard.vue";
import ClientOnly from "vue-client-only";
export default {
    name: "BestSellerProduct",
    data() {
        return {
           
        }
    },
    // async mounted() {
    //     await this.loadBestSellers();
    // },
    computed: {
    bestSellerProduct() {
      return this.$store.getters["product_local/getBestSellerProducts"];
    },
    },

    methods: {
        // async loadBestSellers() {
        //     const lang = getCurrentLanguage();
        //     const storelang = config.languages[lang];
        //     const root_category = storelang["root_category"];
        //     const data = await getBestSellers(root_category);
        //     const productDetails = data.tabs[0].items.map(item => ({
        //         sku: item.sku,
        //         imageUrl: item.image.url,
        //     }));
        //     await this.loadProductDetails(productDetails);
        // },
        // async loadProductDetails(productDetails) {
        //     const products = [];
        //     for (const detail of productDetails) {
        //         const { sku, imageUrl } = detail; // Destructure SKU and image URL
        //         const productData = await getProductBySku(sku);
        //         if (productData && productData.items && productData.items.length) {
        //             const product = productData.items[0];
        //             product.image.medium = imageUrl;
        //             products.push(product);
        //         }
        //     }
        //     this.bestSellerProduct = products;
        // }
    },
    components: {
        VueSlickCarousel,
        ProductCard,
        SkeletonCard,
        ClientOnly
    }
}
</script>