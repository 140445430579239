var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"hero-slider"}},[_c('div',{staticClass:"slider-wrap"},[_c('VueSlickCarousel',{attrs:{"slidesToShow":2,"dots":true,"arrows":false,"infinite":false,"responsive":[
      {
        "breakpoint": 9999,
        "settings": "unslick"
      },
      {
        "breakpoint": 992,
        "settings": {
          "slidesToShow": 1
        }
      }
      ]}},[_vm._l((_vm.slider.slides),function(bSlide,index){return [_c('div',{key:index,staticClass:"col slide p-15",style:({ 'background-image': 'url(' + require('@/esf_kerkrade_vitanatura/assets/images/slider/hero-slider-background.jpeg') + ')' })},[_c('div',{staticClass:"d-flex  "},[(index == 1)?_c('div',{staticClass:"img-container"},[_c('img',{attrs:{"src":bSlide.optImage,"alt":bSlide.title}})]):_vm._e(),_c('div',{staticClass:"card-container w-100 p-0 d-flex"},[_c('div',{staticClass:"card w-100"},[_c('div',{staticClass:"card-body p-0"},[_c('h5',{staticClass:"card-title",domProps:{"innerHTML":_vm._s(bSlide.title)}}),_c('h6',{staticClass:"card-text",domProps:{"innerHTML":_vm._s(bSlide.description)}}),_c('b-link',{staticClass:"slider-link text-uppercase",attrs:{"to":bSlide.link},domProps:{"innerHTML":_vm._s(bSlide.buttonText)}})],1)])]),(index == 0)?_c('div',{staticClass:"img-container"},[_c('img',{attrs:{"src":bSlide.optImage,"alt":bSlide.title}})]):_vm._e()])])]})],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }